import React, { FC } from "react";
import { Trans, t } from "@helpers/translate";
import { IconProps } from "../types";

export const FacebookRound: FC<IconProps> = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="GWGLogoTitle">
        {t({
          id: "common.svg.title",
          message: `GoWithGuide`,
        })}
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path
        d="M16 31.9993C7.1648 31.9993 0 24.8346 0 15.9996C0 7.16463 7.1648 0 16 0C24.8352 0 32 7.16463 32 15.9996C32 24.8346 24.8352 31.9993 16 31.9993Z"
        fill="#3B579D"
      />
      <path
        d="M20.1604 32.0001V19.6068H24.3204L24.9444 14.7781H20.1604V11.6934C20.1604 10.295 20.5476 9.34143 22.554 9.34143H25.1108V5.02473C24.6692 4.96713 23.1492 4.83594 21.3828 4.83594C17.6964 4.83594 15.1716 7.08549 15.1716 11.2198V14.7813H11.002V19.61H15.1716V32.0001H20.1604Z"
        fill="white"
      />
    </svg>
  );
};
