import React, { FC } from "react";
import { Trans, t } from "@helpers/translate";
import { IconProps } from "../types";

export const Star: FC<IconProps> = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <title id="GWGLogoTitle">
        {t({
          id: "common.svg.title",
          message: `GoWithGuide`,
        })}
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path d="M15.3289 5.375H10.3125L8.7881 0.945703C8.71308 0.729883 8.50505 0.59375 8.26975 0.59375C8.03444 0.59375 7.82642 0.729883 7.7514 0.945703L6.22703 5.375H1.17649C0.876395 5.375 0.630859 5.61406 0.630859 5.90625C0.630859 5.93613 0.63427 5.96934 0.64109 5.9959C0.64791 6.11211 0.702474 6.2416 0.869575 6.37109L4.99253 9.2L3.41019 13.6791C3.33175 13.8949 3.41019 14.1373 3.59775 14.2768C3.69665 14.3465 3.78872 14.4062 3.90467 14.4062C4.01721 14.4062 4.1502 14.3498 4.24569 14.2867L8.26975 11.4943L12.2938 14.2867C12.3893 14.3531 12.5223 14.4062 12.6348 14.4062C12.7508 14.4062 12.8429 14.3498 12.9383 14.2768C13.1293 14.1373 13.2043 13.8982 13.1259 13.6791L11.5436 9.2L15.6324 6.34453L15.7313 6.26152C15.82 6.16855 15.9086 6.04238 15.9086 5.90625C15.9086 5.61406 15.629 5.375 15.3289 5.375Z" />
    </svg>
  );
};
