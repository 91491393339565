import React, { FC } from "react";
import Link from "next/link";
import { useAuth } from "@helpers/hooks/useAuth";
import { Trans, t } from "@helpers/translate";
import { CustomerNotification } from "@graphql/types";
import { Badge } from "../../../../../components/ui/Badge";

import { Avatar } from "@components/ui/Avatar";
import { Dropdown } from "@components/ui/Dropdown";
import BloggerDropdown from "../BloggerDropdown";
import AdminDropdown from "../AdminDropdown";

export const UserDropdown: FC<{
  notification?: CustomerNotification;
}> = ({ notification }) => {
  const { auth, logout } = useAuth();

  if (!auth) {
    return null;
  }

  return (
    <div className="flex items-center">
      <Avatar src={auth.picture?.url} size="xs" className="mr-2" />
      <Dropdown title={auth.firstName} direction="bottomRight" minWidth="9rem">
        <Link
          href="/auth/account"
          title={t({
            id: "common.link.title",
            message: "GoWithGuide - Private Tours & Local Tour Guides",
          })}
        >
          <Dropdown.Item>
            <Trans id="navbar.account">Account</Trans>
          </Dropdown.Item>
        </Link>
        <Link
          href="/auth/messages"
          title={t({
            id: "common.link.title",
            message: "GoWithGuide - Private Tours & Local Tour Guides",
          })}
        >
          <Dropdown.Item>
            <Badge count={notification?.unReadMessagesCount}>
              <Trans id="navbar.messages">Messages</Trans>
            </Badge>
          </Dropdown.Item>
        </Link>
        <Link
          href="/auth/bookings"
          title={t({
            id: "common.link.title",
            message: "GoWithGuide - Private Tours & Local Tour Guides",
          })}
        >
          <Dropdown.Item>
            <Trans id="navbar.bookings">Bookings</Trans>
          </Dropdown.Item>
        </Link>
        <Link
          href="/auth/reviews"
          title={t({
            id: "common.link.title",
            message: "GoWithGuide - Private Tours & Local Tour Guides",
          })}
        >
          <Dropdown.Item>
            <Trans id="navbar.reviews">Reviews</Trans>
          </Dropdown.Item>
        </Link>
        <Link
          href="/contact"
          title={t({
            id: "common.link.title",
            message: "GoWithGuide - Private Tours & Local Tour Guides",
          })}
        >
          <Dropdown.Item>
            <Trans id="navbar.help">Help</Trans>
          </Dropdown.Item>
        </Link>

        {auth.isBlogger && <BloggerDropdown />}

        {auth.isAdmin && <AdminDropdown />}

        <Dropdown.Divider />
        <a
          title={t({
            id: "common.link.title",
            message: "GoWithGuide - Private Tours & Local Tour Guides",
          })}
        >
          <Dropdown.Item onClick={() => logout("", true)}>
            <Trans id="navbar.logout">Logout</Trans>
          </Dropdown.Item>
        </a>
      </Dropdown>
    </div>
  );
};

export default UserDropdown;
