import { DefaultSeoProps } from "next-seo";

// GENERAL
export const SITE_NAME = "GoWithGuide";
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || "";
export const IS_LOCAL_ENV = ["development", "local"].includes(
  process.env.NODE_ENV
);
export const IS_PRODUCTION = process.env.NEXT_PUBLIC_ENV === "production";
export const PROXY_SITE_URL = process.env.NEXT_PUBLIC_PROXY || "";
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST || "";
export const MY_SECRET_TOKEN =
  process.env.NEXT_MY_SECRET_TOKEN || "g1qlDrJz56n6fWwYEuQI3dOwT3SV9x9t";

// TRACKING
export const GTM_ID = "GTM-K5VG4T2";
export const META_ID = "172443345825155";
export const BUGSNAG_KEY = "94f596baac860341932b87a18c4e06a2";

// SOCIAL NETWORKS
export const TWITTER = "@gowithguide";
export const EMAIL = "contact@triplelights.com";
export const FACEBOOK_APP_ID = "256761277812546";
export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || "";
export const GOOGLE_OAUTH_URI =
  "https://www.googleapis.com/oauth2/v1/userinfo?access_token=";

// COOKIES
export const COOKIE_TOKEN = "NEXT_TOKEN";
export const COOKIE_USER_ME = "NEXT_USER_ME";
export const COOKIE_LOCALE = "NEXT_LOCALE";
export const COOKIE_FIRST_RENDER_DONE = "NEXT_FIRST_RENDER_DONE";
export const COOKIE_CURRENCY = "NEXT_CURRENCY";
export const COOKIE_REDIRECT_TO = "redirectTo";

// SEO
export const DEFAULT_SEO: DefaultSeoProps = {
  title: SITE_NAME,
  titleTemplate: `%s | ${SITE_NAME}`,
  description: "",
  openGraph: {
    type: "website",
    url: SITE_URL,
    site_name: SITE_NAME,
    images: [{ url: `${SITE_URL}/img/website-card.png` }],
  },
  twitter: {
    handle: TWITTER,
    site: TWITTER,
    cardType: "summary_large_image",
  },
};

// locales
export const DEFAULT_LOCALE = "en";
export const LOCALES = {
  en: "English",
  ja: "日本語",
};
export type ILOCALES = "en" | "ja";
export const CURRENCY_BY_LOCALE = {
  en: "USD",
  ja: "JPY",
} as { [key: string]: string };

// currencies
export const DEFAULT_CURRENCY = "USD";

export const CURRENCIES = [
  "USD",
  "JPY",
  "AUD",
  "CAD",
  "CNY",
  "EUR",
  "GBP",
  "HKD",
  "PHP",
  "SGD",
  "THB",
].sort();

export const COMPANY_NAME = "Travelience inc.";

export const COMPANY_ADDRESS_LINE_1 = "Kuramae Nagatani Bldg 5F-C,";

export const COMPANY_ADDRESS_LINE_2 =
  "2-4-3 Kuramae, Taito-ku, Tokyo, Japan 111-0051";

export const COMPANY_ADDRESS_JAPANESE =
  "〒111-0051 東京都台東区蔵前2-4-3 蔵前永谷ビル5階C";

export const COMPANY_NAME_JAPANESE = "株式会社トラベリエンス";

export const COMPANY_CONTACT_INFO = "+81 (0)3-6830-0896 (English,Japanese)";
// contact us
export const CONTACT_EMAIL = "contact@gowithguide.com";
//notion api token
export const NOTION_API_TOKEN =
  "secret_wUv9CrM9IWKF75Up3qJWLUAHa6Dls3nO192NKomnrEP";

//landing pages page id

export const NOTION_BOOKING_PROCESS_PAGE_ID = {
  en: "b384672aa5754a55b0fcb2129851cb77",
  ja: "b69d6ce2e8d04d5989b111dea3e58714",
} as { [key: string]: string };
export const NOTION_CANCELLATION_POLICY_PAGE_ID = {
  en: "913c43840ec747119f114bd49096b0cc",
  ja: "d93d52a3873240a18fb6f516dae1b0ff",
} as { [key: string]: string };
export const NOTION_PRIVACY_POLICY_PAGE_ID = {
  en: "5bb166a678944ea59257b2bcaf3df9ca",
  ja: "6f11303ca80f4b7d979728179507e8c7",
} as { [key: string]: string };
export const NOTION_TERMS_PAGE_ID = {
  en: "7f1b0daeb81240199a559dc84b946a7f",
  ja: "dcc7f278571b4a298832ddb3ff88a48b",
} as { [key: string]: string };

export const NOTION_TRADE_LAW_PAGE_ID = "7992524e8fbf48bdbac9421d7f52887c"; //ja only

export const NOTION_FAQS_PAGE_ID = {
  ja: "653d8917c3d94cf783fa4a9b8c99c48f",
  en: "89b3227c68064b049b2a7d04be1eae64",
} as { [key: string]: string };
export const NOTION_FAQS_CAT_PAGE_ID = {
  ja: "3a65b056609a407eac8b39611eee8e8d",
  en: "85a5a8625fda4ae9ba6d8e386cab8ec5",
} as { [key: string]: string };

export const NOTION_SUPPLIER_CANCEL_FAQ = {
  ja: "56dac0679ad549b39175b70ddf444f83",
  en: "8eb2990699df4e2aaa74749bbe6c1d1c",
} as { [key: string]: string };

export const NOTION_CUSTOMER_CANCEL_FAQ = {
  ja: "3436991fc31f4b28849387e132a14110",
  en: "5f79e7dd8a4a456a81df72f2b915781b",
} as { [key: string]: string };

const NOTION_URL = "https://notion-api.splitbee.io";
export const NOTION_PAGE_URL = NOTION_URL + "/v1/page/";
export const NOTION_TABLE_URL = NOTION_URL + "/v1/table/";

export const PAYPAL_URL = process.env.NEXT_PUBLIC_PAYPAL_LOGIN_URL || "";
export const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID || "";
export const PAYPAL_REDIRECTION_URL = process.env.NEXT_PUBLIC_DOMAIN || "";
export const PAYPAL_LOGIN_URL = `${PAYPAL_URL}?flowEntry=static&client_id=${PAYPAL_CLIENT_ID}&scope=profile openid https://uri.paypal.com/services/paypalattributes&redirect_uri=${PAYPAL_REDIRECTION_URL}/auth/account/payments`;

//Cache
const ONE_HOUR = 60 * 60;
export const CAN_CACHE_IMAGES = process.env.NEXT_PUBLIC_CACHE_IMAGES == "true";
export const CACHE_TIME = ONE_HOUR;
export const CACHE_TIME_MORE = 5 * ONE_HOUR;

//Veritrans
export const VERITRAN_URL =
  process.env.NEXT_PUBLIC_VERITRAN_TOKEN_URL ||
  "https://api.veritrans.co.jp:443/4gtoken";
export const VERITRAN_KEY =
  process.env.NEXT_PUBLIC_VERITRAN_TOKEN_API_KEY ||
  "a6860348-52cf-4ae6-a3cb-62f0c73ef4d2";

// Route
export const ROUTE_SUPPLIER_APPLY = "/auth/supplier/apply/";
export const ROUTE_SIGNBOARD_PRINT = "/support/signboard/print";

// MAX FILE SIZE SUPPORTED IN MB
export const MAX_FILE_SIZE_SUPPORTED = 5;

// Social Platform Links
export const FACEBOOK_LINK = "https://www.facebook.com/gowithguide.tours";
export const TWITTER_LINK = "https://twitter.com/gowithguide";

//Tour Fee
export const GWG_JAPAN_TOUR_FEE = Number(
  process.env.NEXT_PUBLIC_GWG_JAPAN_TOUR_FEE || "33.5"
);
export const GWG_ABROAD_TOUR_FEE = Number(
  process.env.NEXT_PUBLIC_GWG_ABROAD_TOUR_FEE || "30"
);

//Car Tour FEE
export const GWG_JAPAN_CAR_TOUR_FEE = Number(
  process.env.NEXT_PUBLIC_GWG_JAPAN_CAR_TOUR_FEE || "19.25"
);

export const GWG_ABROAD_CAR_TOUR_FEE = Number(
  process.env.NEXT_PUBLIC_GWG_ABROAD_CAR_TOUR_FEE || "20"
);
export const INSTAGRAM_LINK = "https://www.instagram.com/gowithguide";

export const GOOGLE_HELPFUL_CONTENT_LINK =
  "https://developers.google.com/search/docs/fundamentals/creating-helpful-content";

export const BLOG_TINY_MCE_6_DEPLOY_DATE = "2024-02-14";

export const JAPAN_ID = "JP";

export const PLACEHOLDER_IMAGE = "/img/img-placeholder.avif";

export const NEW_ADMIN = "https://admin.gowithguide.com";
