import React, { ReactNode } from "react";
import { FC } from "react";
import { CountryCardFragment } from "@graphql/types";
import { Title } from "@components/ui/Title";
import LocationCard from "@components/common/LocationCard/LocationCard";

interface Props {
  title: string;
  data?: CountryCardFragment[];
  children?: ReactNode;
}

const CountriesBlock: FC<Props> = ({ title, data, children }) => {
  return (
    <div className="mb-10">
      <Title as="h2" size="md" divider>
        {title}
      </Title>
      <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4">
        {data && data.map((node) => <LocationCard key={node.id} {...node} />)}
      </div>
      {children && <div className="text-center mt-8">{children}</div>}
    </div>
  );
};

export default CountriesBlock;
