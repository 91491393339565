import { CAN_CACHE_IMAGES, SITE_NAME } from "@constants";
import NextImage, { ImageProps } from "next/image";
import React, { FC, useEffect, useState } from "react";

// IN some cases src is coming as string and in some cases mainly for PhotosUpdateGallery.tsx,
// Its coming as json. Direct object cannot be sent
// {
//   "caption":"",
//   "id":0,
//   "mobileUrl":"",
//   "src":"",
//    "serpImage":""
// }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSrcAsString = (value: string | any): string => {
  // Check if the value is a string
  if (typeof value === "string") {
    return value;
  }

  // Check if the value matches the ImageObject structure
  if (
    typeof value === "object" &&
    value !== null &&
    "src" in value &&
    typeof value.src === "string"
  ) {
    return value.src;
  }

  throw new Error(
    "Invalid input: Expected a string or an ImageObject with a src property."
  );
};

type IProps = {
  activeCache: boolean;
  fallback?: string;
} & Omit<ImageProps, "alt"> & { alt?: string };

export const Image: FC<IProps> = ({
  activeCache,
  alt = SITE_NAME,
  ...props
}) => {
  if (!props.src) {
    return null;
  }

  const [src, setSrc] = useState<string | undefined>(getSrcAsString(props.src));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any>();

  // On error, prepare the fallback image url
  useEffect(() => {
    if (error) {
      if (props.fallback) {
        setSrc(props.fallback || "");
      } else {
        setSrc(getFallBackUrlFromAvifUrl());
      }
    } else {
      setSrc(props.src as string);
    }
  }, [error, props.src]);

  useEffect(() => {
    setSrc(getSrcAsString(props.src));
  }, [props.src]);

  const getFallBackUrlFromAvifUrl = () => {
    // Image avif url:
    // localhost:3000/images/892A3HJBFHYQpZyhfaOvczWF0Tmm_v0Bj80aSWFPJiM/f:avif/h:480/w:480/plain/gowithguide/cities/2107/86155.jpg

    // Get the s3 path from the source url of gowithguide folder
    let urls = (props?.src).toString().split("plain/gowithguide/");
    if (urls.length > 1) {
      const fallBackUrl = `${
        process.env.NEXT_PUBLIC_S3_BASE_URL
          ? process.env.NEXT_PUBLIC_S3_BASE_URL
          : "https://travelience-cdn.s3.amazonaws.com"
      }/gowithguide/${urls[1]}`;

      return fallBackUrl;
    }

    // Get the s3 path from the source url of planetyze folder
    urls = (props?.src).toString().split("plain/planetyze/");
    if (urls.length > 1) {
      const fallBackUrl = `${
        process.env.NEXT_PUBLIC_S3_BASE_URL
          ? process.env.NEXT_PUBLIC_S3_BASE_URL
          : "https://travelience-cdn.s3.amazonaws.com"
      }/planetyze/${urls[1]}`;

      return fallBackUrl;
    }

    urls = (props?.src).toString().split("plain/gowithguide-testing/");
    if (urls.length > 1) {
      const fallBackUrl = `${
        process.env.NEXT_PUBLIC_S3_BASE_URL
          ? process.env.NEXT_PUBLIC_S3_BASE_URL
          : "https://travelience-cdn.s3.amazonaws.com"
      }/gowithguide-testing/${urls[1]}`;

      return fallBackUrl;
    }

    return getSrcAsString(props.src || "");
  };

  return (
    <NextImage
      {...props}
      alt={alt}
      src={src || props.src}
      onError={(e) => {
        // If no error is set, then we need to set the error. Else this will cause continuos rendering in infinity loop
        if (!error) {
          setError(e);
        }
      }}
      unoptimized={CAN_CACHE_IMAGES ? !activeCache : true}
      style={{
        maxWidth: "100%",
      }}
    />
  );
};
