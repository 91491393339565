import { Button } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";
import { Trans } from "@helpers/translate";
import React, { FC } from "react";

interface LoadMoreProps {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
}

const LoadMore: FC<LoadMoreProps> = ({
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
}) => {
  if (!hasNextPage) {
    return <React.Fragment />;
  }

  return (
    <div className="flex items-center justify-center">
      {isFetchingNextPage && <Spinner />}
      {!isFetchingNextPage && (
        <Button
          variant="default"
          className="px-10"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          <Trans id="pagination.button.load-more">Load More</Trans>
        </Button>
      )}
    </div>
  );
};

export default LoadMore;
