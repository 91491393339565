import React from "react";
import { Trans, t } from "@helpers/translate";
import Link from "next/link";
import { Dropdown } from "@components/ui/Dropdown";

const BloggerDropdown = () => {
  return (
    <>
      <Link
        href="/auth/posts"
        title={t({
          id: "common.link.title",
          message: "GoWithGuide - Private Tours & Local Tour Guides",
        })}
      >
        <Dropdown.Item>
          <Trans id="navbar.blog">Blog</Trans>
        </Dropdown.Item>
      </Link>
    </>
  );
};

export default BloggerDropdown;
