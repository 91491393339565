import React from "react";
import Link from "next/link";
import { t } from "@helpers/translate";
import { Dropdown } from "@components/ui/Dropdown";
import { NEW_ADMIN } from "@constants";

const AdminDropdown = () => {
  return (
    <>
      <Dropdown.Divider />
      <Link
        href={NEW_ADMIN}
        title={t({
          id: "common.link.title",
          message: "GoWithGuide - Private Tours & Local Tour Guides",
        })}
      >
        <Dropdown.Item>Admin</Dropdown.Item>
      </Link>
    </>
  );
};

export default AdminDropdown;
