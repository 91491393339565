import React, { createElement, FC, HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

type Tag = "h1" | "h2" | "h3" | "h4";
export interface TitleProps extends HTMLAttributes<HTMLElement> {
  centered?: boolean;
  divider?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
  as?: Tag;
  variant?: "default" | "subtitle";
  children?: ReactNode;
}

const sizes = {
  xs: "text-lg sm:text-xl",
  sm: "text-xl sm:text-2xl",
  md: "text-2xl sm:text-3xl",
  lg: "text-3xl sm:text-4xl",
  xl: "text-4xl sm:text-5xl",
  "2xl": "text-5xl sm:text-6xl",
};

const variants = {
  default: {
    text: "",
    divider: "border-primary",
  },

  subtitle: {
    text: "text-secondary",
    divider: "border-gray-300",
  },
};

export const Title: FC<TitleProps> = ({
  centered,
  className = "",
  children,
  divider = false,
  size = "2xl",
  as = "h1",
  variant = "default",
  ...props
}) => {
  const styles = variants[variant];

  const classes = twMerge(
    `mb-10 w-full ${centered ? "text-center" : ""}`,
    classNames("font-normal", sizes[size], styles.text),
    className
  );

  const dividerClassName = classNames(
    "border-t border-2 block w-16 mt-2",
    styles.divider,
    {
      "m-auto": centered,
    }
  );

  const Content = (
    <>
      {children}
      {divider && <div className={dividerClassName} />}
    </>
  );

  return createElement(as, { className: classes, ...props }, Content);
};
