import React, { FC } from "react";
import { Trans, t } from "@helpers/translate";
import { IconProps } from "../types";

export const Error: FC<IconProps> = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill={"none"}
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <title id="GWGLogoTitle">
        {t({
          id: "common.svg.title",
          message: `GoWithGuide`,
        })}
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path
        d="M12 2.25C6.61406 2.25 2.25 6.61406 2.25 12C2.25 17.3859 6.61406 21.75 12 21.75C17.3859 21.75 21.75 17.3859 21.75 12C21.75 6.61406 17.3859 2.25 12 2.25ZM14.4703 15.5297L12 13.0594L9.52969 15.5297C9.23906 15.8203 8.76094 15.8203 8.47031 15.5297C8.325 15.3844 8.25 15.1922 8.25 15C8.25 14.8078 8.325 14.6156 8.47031 14.4703L10.9406 12L8.47031 9.52969C8.325 9.38438 8.25 9.19219 8.25 9C8.25 8.80781 8.325 8.61562 8.47031 8.47031C8.76094 8.17969 9.23906 8.17969 9.52969 8.47031L12 10.9406L14.4703 8.47031C14.7609 8.17969 15.2391 8.17969 15.5297 8.47031C15.8203 8.76094 15.8203 9.23906 15.5297 9.52969L13.0594 12L15.5297 14.4703C15.8203 14.7609 15.8203 15.2391 15.5297 15.5297C15.2391 15.825 14.7609 15.825 14.4703 15.5297Z"
        fill={fill}
      />
    </svg>
  );
};
