import GlobalSearch from "@components/common/GlobalSearch";
import Icon from "@components/ui/Icon";
import ModalAnimation from "@components/ui/ModalAnimation/ModalAnimation";
import { Trans } from "@helpers/translate";
import React, { FC } from "react";

const InputDestination: FC<{
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
}> = ({ isOpen = false, setIsOpen }) => {
  return (
    <div>
      {/* // desktop */}
      <div className={`hidden md:flex w-full top-0 left-0`}>
        <GlobalSearch
          id="global-search-navbar1"
          className={`border-gray-200 bg-gray-100  shadow-none border-0 p-3`}
          isOpen={isOpen}
          onMenuChange={(isOpen) => setIsOpen && setIsOpen(isOpen)}
        />
      </div>
      {/* // mobile */}
      <div
        className={`fixed flex flex-col md:hidden w-full top-0 left-0 bg-white h-full overflow-y-auto ${
          isOpen ? "flex" : "hidden"
        }`}
      >
        <ModalAnimation
          visible={isOpen}
          onClose={() => {
            setIsOpen && setIsOpen(isOpen);
          }}
        >
          <button
            className="flex flex-row items-center mt-4 ml-2 text-gray-600"
            onClick={() => {
              setIsOpen && setIsOpen(false);
            }}
          >
            <Icon icon="arrowBack" />
            <div className="ml-4 font-bold">
              <Trans id="searchbox.modal.close">Close</Trans>
            </div>
          </button>
          <div className="flex w-full h-full rounded-t-3xl pt-8 shadow-lg-simple px-8 mt-4">
            <GlobalSearch
              id="global-search-navbar2"
              className={`border-gray-200 bg-gray-100  shadow-none border-0 p-3`}
              isOpen={isOpen}
              onMenuChange={(isOpen) => setIsOpen && setIsOpen(isOpen)}
              numberOfResults={7}
            />
          </div>
        </ModalAnimation>
      </div>
    </div>
  );
};

export default InputDestination;
