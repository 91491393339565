import React, { FC } from "react";
import { Trans, t } from "@helpers/translate";
import { IconProps } from "../types";

export const Chat: FC<IconProps> = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <title id="GWGLogoTitle">
        {t({
          id: "common.svg.title",
          message: `GoWithGuide`,
        })}
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
        d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
      />
    </svg>
  );
};
