import { Alert } from "@components/ui/Alert";
import Icon from "@components/ui/Icon";
import { graphql } from "@helpers/graphql";
import { useAuth } from "@helpers/hooks/useAuth";
import { Trans, t } from "@helpers/translate";
import React, { FC, useEffect, useState } from "react";

const SupplierUpdateCalendarAlert: FC = () => {
  const { auth } = useAuth();

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!!auth?.id && !!auth.isSupplier) {
      graphql
        .supplierById({
          id: auth.id,
        })
        .then((results) => {
          if (!results.supplierById?.calendarUpdatedAt) {
            setShowAlert(true);
          } else {
            const today = new Date();
            const diffTime =
              today.getTime() -
              new Date(results.supplierById.calendarUpdatedAt).getTime();
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setShowAlert(diffDays >= 7);
          }
        });
    }
  }, [auth]);

  return (
    <div className="relative">
      <div className="absolute top-0 left-0 z-10 w-full">
        {auth && showAlert && (
          <Alert variant="error" showIcon={false} centered className="p-0 py-4">
            <div className="flex justify-center items-center w-full">
              <div className="absolute left-4">
                <Icon
                  icon="close"
                  size="sm"
                  className="cursor-pointer"
                  onClick={() => {
                    setShowAlert(false);
                  }}
                />
              </div>
              <div className="flex flex-col justify-center items-center w-full px-5 justify-items-center text-center ml-8 lg:ml-0">
                <div>
                  <Trans id="auth.suppliers.apply.alert-pending-calendar-update">
                    Your calendar hasn&apos;t been updated for more than 7 days.
                    This will increase the risk of double bookings or
                    cancellations.
                  </Trans>
                </div>
                <div className="link ml-1">
                  <a
                    target="_blank"
                    href="/auth/supplier/calendar"
                    title={t({
                      id: "common.link.title",
                      message:
                        "GoWithGuide - Private Tours & Local Tour Guides",
                    })}
                    className="text-blue-500 font-bold"
                  >
                    <Trans id="auth.suppliers.apply.alert-calendar-outdated">
                      Click here to update your calendar
                    </Trans>
                  </a>
                </div>
              </div>
            </div>
          </Alert>
        )}
      </div>
    </div>
  );
};

export default SupplierUpdateCalendarAlert;
