import React, { FC, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { t } from "@helpers/translate";
import client from "@helpers/graphqlApollo";
import { gql } from "@apollo/client";
import { AutoComplete, AutoCompleteItem } from "@components/ui/AutoComplete";
import { searchURL } from "@helpers/searchURL";
import { CategoryTypes } from "../Category";
import { Location } from "@graphql/types";

interface GlobalSearchProps {
  id?: string;
  className?: string;
  isOpen?: boolean;
  onMenuChange?: (isOpen: boolean) => void;
  placeholder?: string;
  numberOfResults?: number;
}

const GlobalSearch: FC<GlobalSearchProps> = ({
  id = "global-search",
  className = "",
  isOpen = false,
  onMenuChange,
  placeholder,
  numberOfResults = 10,
}) => {
  const router = useRouter();

  const [value, setValue] = useState("");
  const [allResults, setAllResults] = useState<
    { wordSearched: string; data: AutoCompleteItem[] }[]
  >([]); // debounce calls are being overwrite by sloweer calls, so we created the array of result to always just show the last result based to text typed

  const onSearch = async (text: string) => {
    setValue(text);
    if (!text) {
      return;
    }

    const singaporeCityId = 4386;

    const data = await client.query({
      query: gql`
        query locations($limit: Int!, $filters: LocationFilters) {
          locations(limit: $limit, filters: $filters) {
            __typename
            ... on City {
              name
              url
              slug
              country {
                name
              }
            }
            ... on Country {
              name
              url
              slug
            }
          }
        }
      `,
      variables: {
        limit: numberOfResults,
        filters: {
          MATCH: text,
          EXCLUDE_IDS: [singaporeCityId], // do not list cities with singapore id
        },
      },
    });

    const newData =
      data.data.locations.map((item: Location) => ({
        search: item.name,
        ...item,
      })) || [];

    setAllResults((old) => [
      ...old,
      {
        wordSearched: text,
        data: newData,
      },
    ]);
  };

  // we are facing out a problem when using debounce to search the locations,
  // previous calls that load after the last call will overwrite the result,
  // showing wrong list of locations, so we created a array of results
  // and verify on array the right result to word typed on box
  const currentResults = useMemo<AutoCompleteItem[]>(() => {
    return allResults.find((a) => a.wordSearched === value)?.data || [];
  }, [allResults]);

  return (
    <div className="w-full mx-auto">
      <AutoComplete
        id={id}
        isOpen={isOpen}
        placeholder={
          placeholder ||
          t({
            id: "global-search.placeholder",
            message: "Where to?",
          })
        }
        className={className}
        itemToString={(item) => item.search}
        items={currentResults}
        onChange={async (item) => {
          if (item.__typename === "Country") {
            router.push(
              searchURL({
                country: item.slug,
                t: CategoryTypes.guides,
              })
            );
          } else {
            router.push(
              searchURL({
                city: item.slug,
                t: CategoryTypes.guides,
              })
            );
          }
        }}
        onSearch={onSearch}
        onSubmit={async (value) => {
          if (value) {
            router.push(
              searchURL({
                q: value,
                t: CategoryTypes.tours,
              })
            );
          }
        }}
        onMenuChange={onMenuChange}
        renderItem={(item) => (
          <AutoComplete.Item>
            {item.name}
            {item.country && <span>, {item.country.name}</span>}
          </AutoComplete.Item>
        )}
      />
    </div>
  );
};

export default GlobalSearch;
