import React, { FC } from "react";
import { Trans, t } from "@helpers/translate";
import { IconProps } from "../types";

export const Warning: FC<IconProps> = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <title id="GWGLogoTitle">
        {t({
          id: "common.svg.title",
          message: `GoWithGuide`,
        })}
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
        d="M10.7297 3.74531L2.42815 18.8953C1.90315 19.8422 2.60159 21 3.69847 21H20.3063C21.3985 21 22.0969 19.8422 21.5766 18.8953L13.2703 3.74531C12.7219 2.75156 11.2782 2.75156 10.7297 3.74531V3.74531ZM12.825 10.0312L12.6563 15.75H11.3438L11.175 10.0312H12.825ZM12 18.8625C11.4985 18.8625 11.1047 18.4828 11.1047 18C11.1047 17.5172 11.4985 17.1375 12 17.1375C12.5016 17.1375 12.8953 17.5172 12.8953 18C12.8953 18.4828 12.5016 18.8625 12 18.8625Z"
        fill={fill}
      />
    </svg>
  );
};
