import { t } from "@helpers/translate";
import classNames from "classnames";
import React, { FC, ReactNode } from "react";
import Image from "next/image";

import { twMerge } from "tailwind-merge";
import { Breadcrumb, BreadcrumbItem } from "@components/ui/Breadcrumb";
import { Container } from "@components/ui/Container";
import BreadcrumbMicroFormat from "../MicroFormat/BreadcrumbMicroFormat";
import { Rating } from "@components/ui/Rating";

interface HeroProps {
  className?: string;
  title: string;
  subtitle?: string;
  src: string | undefined;
  alt: string;
  srcSet?: string;
  breadcrumb?: BreadcrumbItem[];
  reviewsCount?: number;
  reviewsRate?: number;
  fallback?: string;
  placeholder?: string;
  children?: ReactNode;
}

const Hero: FC<HeroProps> = ({
  children,
  title,
  subtitle,
  className = "",
  src,
  alt,
  breadcrumb,
  reviewsCount,
  reviewsRate,
}) => {
  const classes = classNames(
    "relative bg-blue-50 w-full bg-cover bg-right",
    className,
    {
      "h-hero-xs md:h-hero-xxs": !className.includes("h-"),
    }
  );

  if (breadcrumb) {
    const homeLink = {
      text: t({ id: "breadcrumb.top", message: "Home" }),
      href: "/",
    };

    breadcrumb = [homeLink, ...breadcrumb];
  }

  // const [imageSrc, setImageSrc] = useState<string | undefined>();

  return (
    <div className={classes}>
      {src && (
        <Image
          src={src}
          alt={alt}
          quality={80}
          priority={true}
          fetchPriority="high"
          className={twMerge(
            "w-full h-full object-cover bg-blue-50",
            className
          )}
          width={1080}
          height={720}
          unoptimized={false}
        />
      )}
      <div
        className="absolute w-full h-full top-0 left-0"
        style={{
          background: `linear-gradient(359.86deg, rgba(0, 0, 0, 0.50) 15%, rgba(255, 255, 255, 0) 99.9%)`,
        }}
      >
        <Container className="relative h-full flex flex-col justify-center items-center p-2 px-4 md:px-8 max-w-6xl m-auto">
          {breadcrumb && (
            <div className="mt-4">
              <Breadcrumb items={breadcrumb} variant="hero" />
              <BreadcrumbMicroFormat items={breadcrumb} />
            </div>
          )}

          <div className="mt-6">
            <div className="text-white text-center w-full">
              <h1 className="font-semibold text-shadow text-2xl xs:text-hero">
                {title}
              </h1>
              {subtitle && (
                <h2
                  className="mt-4 text-lg md:text-xl  max-w-2xl mx-auto text-shadow"
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                />
              )}
            </div>
          </div>

          {!!reviewsCount && !!reviewsRate && (
            <div className=" text-white text-center mt-4">
              <a
                title={t({
                  id: "common.link.title",
                  message: "GoWithGuide - Private Tours & Local Tour Guides",
                })}
                href="#reviews"
              >
                {reviewsRate > 0 && (
                  <Rating
                    rate={reviewsRate}
                    count={5}
                    variant="stars"
                    alwaysShowDecimals={true}
                  />
                )}
                <p>View all {reviewsCount} reviews</p>
              </a>
            </div>
          )}
          {children && <div className="w-full max-w-4xl">{children}</div>}
        </Container>
      </div>
    </div>
  );
};

export default Hero;
