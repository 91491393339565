import { BreadcrumbItem } from "@components/ui/Breadcrumb";
import React, { FC } from "react";
import MicroFormat from "..";
import { SITE_URL } from "@constants";

interface Props {
  items: BreadcrumbItem[];
}

const BreadcrumbMicroFormat: FC<Props> = ({ items }) => {
  const itemListElement: Array<unknown> = [];

  items.map((item, index) => {
    itemListElement.push({
      "@type": "ListItem",
      position: index + 1,
      name: item.text,
      item: `${SITE_URL}${item.href === "/" ? "" : item.href}`,
    });
  });

  const data = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement,
  };

  return <MicroFormat data={data} />;
};

export default BreadcrumbMicroFormat;
