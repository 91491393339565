import React, { HTMLAttributes, ReactNode } from "react";
import { FC } from "react";
import { CityCardFragment } from "@graphql/types";
import { Title } from "@components/ui/Title";
import LocationCard from "@components/common/LocationCard/LocationCard";
import { twMerge } from "tailwind-merge";

interface Props {
  title: string;
  data?: CityCardFragment[];
  columns?: number;
  className?: HTMLAttributes<HTMLDivElement>["className"];
  children?: ReactNode;
}

export const CitiesBlock: FC<Props> = ({
  title,
  data,
  children,
  columns = 8,
  className,
}) => {
  const grid = {
    lg: columns,
    md: Math.min(6, columns - 2),
    sm: Math.min(4, columns - 4),
    xs: 3,
  };
  const classNameContent = twMerge(
    `grid gap-4 grid-cols-${grid.xs} sm:grid-cols-${grid.sm} md:grid-cols-${grid.md} lg:grid-cols-${grid.lg}`,
    className
  );

  if (data?.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      <Title as="h2" size="md" divider>
        {title}
      </Title>
      <div className={classNameContent}>
        {data && data.map((node) => <LocationCard key={node.id} {...node} />)}
      </div>
      {children && <div className="text-center mt-8">{children}</div>}
    </div>
  );
};
