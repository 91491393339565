import React from "react";
import { DefaultSeo } from "next-seo";
import { DEFAULT_SEO } from "@constants";
import { t } from "@helpers/translate";
import NextHead from "next/head";

const DefaultHead = () => {
  const SEO = {
    ...DEFAULT_SEO,
    description: t({
      id: "default.seo.description",
      message:
        "Connect with Local Tour Guides and Enjoy Private Tours from All over the World. Request an Itinerary and Customize Your Tour!",
    }),
  };

  return (
    <>
      <DefaultSeo {...SEO} />
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </NextHead>
    </>
  );
};

export default DefaultHead;
