import {
  COOKIE_FIRST_RENDER_DONE,
  COOKIE_LOCALE,
  DEFAULT_LOCALE,
  ILOCALES,
  LOCALES,
} from "@constants";
import cookies from "@helpers/cookies";
import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useState,
} from "react";

type LocalesContextState = {
  locale: "en" | "ja";
  changeLocale: (value: "en" | "ja") => void;
};

export const LocalesContext = createContext<LocalesContextState>(
  {} as LocalesContextState
);

export const LocalesProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [locale, setLocale] = useState<ILOCALES>(DEFAULT_LOCALE);

  const isValidLocale = (locale: string) => {
    return Object.keys(LOCALES).includes(locale);
  };

  const changeLocale = (newLocale: ILOCALES) => {
    if (!isValidLocale(newLocale)) {
      return;
    }

    cookies().set(COOKIE_LOCALE, newLocale);

    setLocale(newLocale);

    // clean up
    setTimeout(() => {
      cookies().remove(COOKIE_FIRST_RENDER_DONE);
    }, 2000);
  };

  useEffect(() => {
    const localeCache = (cookies().get(COOKIE_LOCALE) ||
      DEFAULT_LOCALE) as ILOCALES;
    if (localeCache !== locale) {
      // workaround to prevent hydration issue, just dispatch to japanese
      cookies().set(COOKIE_FIRST_RENDER_DONE, "true");
      setTimeout(() => {
        changeLocale(localeCache);
      }, 1000);
    }
  }, [locale]);

  const value = {
    locale,
    changeLocale,
  };

  return (
    <LocalesContext.Provider value={value}>{children}</LocalesContext.Provider>
  );
};
