import React, { FC } from "react";
import { Trans, t } from "@helpers/translate";
import { IconProps } from "../types";

export const Success: FC<IconProps> = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill={"none"}
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      {t({
        id: "common.svg.title",
        message: `GoWithGuide`,
      })}
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path
        d="M12 2.25C6.61406 2.25 2.25 6.61406 2.25 12C2.25 17.3859 6.61406 21.75 12 21.75C17.3859 21.75 21.75 17.3859 21.75 12C21.75 6.61406 17.3859 2.25 12 2.25ZM16.9922 9.30469L10.725 15.6H10.7203C10.6406 15.6797 10.425 15.8578 10.1766 15.8578C9.99844 15.8578 9.79687 15.7594 9.62812 15.5906L7.00312 12.9656C6.92812 12.8906 6.92812 12.7734 7.00312 12.6984L7.8375 11.8641C7.875 11.8266 7.92188 11.8078 7.96875 11.8078C8.01562 11.8078 8.0625 11.8266 8.1 11.8641L10.1812 13.9453L15.9 8.18438C15.9375 8.14688 15.9844 8.12812 16.0312 8.12812C16.0828 8.12812 16.1297 8.14688 16.1625 8.18438L16.9828 9.03281C17.0672 9.1125 17.0672 9.22969 16.9922 9.30469V9.30469Z"
        fill={fill}
      />
    </svg>
  );
};
