import React, { FC } from "react";
import { IconProps } from "../types";

export const X: FC<IconProps> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      stroke="currentcolor"
      strokeWidth={4}
      {...props}
    >
      <path d="m6 6 20 20M26 6 6 26"></path>
    </svg>
  );
};
