import React, { FC, HTMLAttributes, Ref } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

type IPros = {
  wrapperClassName: HTMLAttributes<HTMLDivElement>["className"];
  inputClassName: HTMLAttributes<HTMLDivElement>["className"];
};

export const Input: FC<IPros> = React.forwardRef(
  (
    { wrapperClassName = "", inputClassName = "", ...props },
    ref: Ref<HTMLInputElement>
  ) => {
    const classes = classNames(
      "shadow-sm w-full flex items-center rounded-lg bg-white p-4"
    );

    return (
      <div className={twMerge(classes, wrapperClassName)}>
        <svg
          className="w-6 h-6 mr-3"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.5}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
        <input
          type="text"
          className={twMerge(
            "w-full outline-none text-lg bg-transparent pr-6",
            inputClassName
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);

Input.displayName = "Input";
