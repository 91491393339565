import { useAuth } from "@helpers/hooks/useAuth";
import { Trans, t } from "@helpers/translate";
import { useRouter } from "next/router";
import React, { FC, useState } from "react";
import cookies from "@helpers/cookies";
import { Alert } from "@components/ui/Alert";
import Icon from "@components/ui/Icon";

const SupplierApplyAlert: FC = () => {
  const SUPPLIER_APPLY_COOKIE_ALERT = "_gwg_hide_supplier_apply";
  const { auth } = useAuth();
  const router = useRouter();

  const [showAlert, setShowAlert] = useState(
    (cookies().get(SUPPLIER_APPLY_COOKIE_ALERT) || "0") !== "1"
  );

  return (
    <div className="relative z-10">
      <div className="absolute top-0 left-0 w-full">
        {!router.asPath.includes("/auth/supplier/apply") &&
          auth &&
          showAlert &&
          auth.isSupplierApply === true &&
          auth.guideApplyStep != "submit" && (
            <Alert variant="warning" showIcon={false} centered className="p-3">
              <div className="flex justify-center items-center w-full">
                <div className="absolute left-4">
                  <Icon
                    icon="close"
                    size="sm"
                    className="cursor-pointer"
                    onClick={() => {
                      cookies().set(SUPPLIER_APPLY_COOKIE_ALERT, "1");
                      setShowAlert(false);
                    }}
                  />
                </div>
                <div className="lg:flex justify-center items-center px-5 justify-items-center text-center mx-auto">
                  <div>
                    <Trans id="auth.suppliers.apply.alert-pending-alert">
                      Your supplier application is still pending.
                    </Trans>
                  </div>
                  <div className="link ml-1">
                    <a
                      target="_blank"
                      href="/auth/supplier/apply"
                      title={t({
                        id: "common.link.title",
                        message:
                          "GoWithGuide - Private Tours & Local Tour Guides",
                      })}
                      className="text-blue-500 font-bold"
                    >
                      <Trans id="auth.suppliers.apply.alert-pending-complete">
                        Please click here to complete it
                      </Trans>
                    </a>
                  </div>
                </div>
              </div>
            </Alert>
          )}
      </div>
    </div>
  );
};

export default SupplierApplyAlert;
