import { useBlockScroll } from "@helpers/hooks/useBlockScroll";
import classNames from "classnames";
import Router from "next/router";
import React, { FC, ReactNode } from "react";

import Icon from "../Icon";

import { isMobile } from "react-device-detect";

export const onCloseModal = () => {
  Router.push(Router.asPath, undefined, {
    shallow: true,
  });
};

const Overlay: FC<{ className?: string; children?: ReactNode }> = ({
  children,
  className = "",
}) => {
  const classes = classNames(
    "fixed top-0 right-0 left-0 bottom-0 w-full bg-gray-600 bg-opacity-50 z-30 flex items-start md:justify-center md:p-10",
    className,
    {
      "md:pt-16": !className.includes("pt-"),
    }
  );
  return <div className={classes}>{children}</div>;
};

interface ModalProps {
  title: string;
  className?: string;
  wrapperClassName?: string;
  variant?: "default" | "mobile";
  onClose?: () => void;
  BlockScroll?: boolean;
  customizeAsPerSizeZoom?: boolean; // Used this to control view in web, mobile & web with zoomed in option
  children?: ReactNode;
}

const Modal: FC<ModalProps> = ({
  title,
  onClose = onCloseModal,
  children,
  className,
  variant = "default",
  wrapperClassName = "",
  BlockScroll = true,
  customizeAsPerSizeZoom: customizeAsPerSize = false,
}) => {
  if (BlockScroll) useBlockScroll();

  wrapperClassName = classNames(
    `fixed w-full md:h-auto bg-white md:w-2/3 lg:w-1/2 overflow-hidden shadow-lg md:max-w-screen-sm md:transform md:-translate-x-1/2 ${wrapperClassName}`,
    {
      "h-full rounded": variant === "default",
      "h-auto rounded-t-xl md:rounded-t bottom-0 md:inset-y-0 md:bottom-auto md:mt-10":
        variant === "mobile",
    }
  );

  return (
    <Overlay className={className}>
      <div className="relative">
        <button
          onClick={() => onClose()}
          tabIndex={-1}
          className="fixed inset-0 h-full w-full cursor-default"
        ></button>
        <div className={wrapperClassName}>
          <div className="flex items-center justify-between p-3 border-b ">
            <h4 className="text-lg text-black">{title}</h4>
            <Icon
              icon="close"
              className="text-2xl text-gray-500 cursor-pointer hover:text-gray-600"
              onClick={() => onClose()}
            />
          </div>

          {!customizeAsPerSize && (
            <div className="p-3 md:p-5 overflow-y-auto max-h-full">
              {children}
            </div>
          )}

          {customizeAsPerSize && (
            <div>
              {/* Normal Webview  when its not zoomed in. */}
              <div className="p-3 md:p-5 overflow-y-auto max-h-full md:hidden lg:block sm:hidden max-md:hidden">
                {children}
              </div>
              {/*  Web & when zoomed in to handle scroll*/}
              {!isMobile && (
                <div className="p-3 md:p-5 overflow-y-auto max-h-full lg:hidden h-72">
                  {children}
                </div>
              )}

              {/* Mobile view Hanlde normally */}
              {isMobile && (
                <div className="p-3 md:p-5 overflow-y-auto max-h-full">
                  {children}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Overlay>
  );
};

export default Modal;
