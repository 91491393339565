import { SITE_NAME, SITE_URL } from "@constants";
import React from "react";
import MicroFormat from "..";

const SearchMicroFormat = () => {
  const data = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    url: SITE_URL,
    name: SITE_NAME,
    potentialAction: {
      "@type": "SearchAction",
      target: `${SITE_URL}/s?q={search_term_string}`,
      "query-input": "required name=search_term_string",
    },
  };

  return <MicroFormat data={data} />;
};

export default SearchMicroFormat;
