import React, { FC } from "react";
import { Trans, t } from "@helpers/translate";
import { IconProps } from "../types";

export const Info: FC<IconProps> = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill={"none"}
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <title id="GWGLogoTitle">
        {t({
          id: "common.svg.title",
          message: `GoWithGuide`,
        })}
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <circle cx="10" cy="10" r="10" fill={fill} />
      <path
        d="M10.9844 12.6484H9.35156L9.17188 4.625H11.1719L10.9844 12.6484ZM9.11719 15.1016C9.11719 14.8047 9.21354 14.5599 9.40625 14.3672C9.59896 14.1693 9.86198 14.0703 10.1953 14.0703C10.5286 14.0703 10.7917 14.1693 10.9844 14.3672C11.1771 14.5599 11.2734 14.8047 11.2734 15.1016C11.2734 15.388 11.1797 15.6276 10.9922 15.8203C10.8047 16.013 10.5391 16.1094 10.1953 16.1094C9.85156 16.1094 9.58594 16.013 9.39844 15.8203C9.21094 15.6276 9.11719 15.388 9.11719 15.1016Z"
        fill="white"
      />
    </svg>
  );
};
