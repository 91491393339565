import React, { FC } from "react";
import { Trans, t } from "@helpers/translate";
import { IconProps } from "../types";

export const Twitter: FC<IconProps> = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="GWGLogoTitle">
        {t({
          id: "common.svg.title",
          message: `GoWithGuide`,
        })}
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path
        d="M16 0C24.8352 0 32 7.1648 32 16C32 24.8352 24.8352 32 16 32C7.1648 32 0 24.8352 0 16C0 7.1648 7.1648 0 16 0Z"
        fill="#1DA1F2"
      />
      <path
        d="M12.2875 24.1278C19.8331 24.1278 23.9643 17.875 23.9643 12.451C23.9643 12.275 23.9643 12.0958 23.9515 11.9198C24.7547 11.3374 25.4491 10.6206 25.9995 9.79497C25.2507 10.1278 24.4571 10.3454 23.6443 10.4414C24.5019 9.92937 25.1419 9.12297 25.4491 8.17257C24.6427 8.64937 23.7627 8.98857 22.8443 9.16777C21.2891 7.51657 18.6907 7.43657 17.0395 8.98857C15.9739 9.99017 15.5227 11.4846 15.8523 12.9086C12.5531 12.7422 9.47786 11.1838 7.39466 8.62057C6.30666 10.4958 6.86026 12.8926 8.66506 14.099C8.01226 14.0798 7.37226 13.9038 6.80266 13.587C6.80266 13.603 6.80266 13.6222 6.80266 13.6382C6.80266 15.5902 8.17866 17.2734 10.0955 17.6606C9.49066 17.8238 8.85706 17.8494 8.24266 17.731C8.78026 19.4014 10.3195 20.547 12.0763 20.5822C10.6235 21.7246 8.82826 22.3454 6.98186 22.3422C6.65546 22.3422 6.32906 22.323 6.00586 22.2814C7.87466 23.4878 10.0603 24.1278 12.2875 24.1246"
        fill="white"
      />
    </svg>
  );
};
