import React, { createContext, FC, ReactNode, useState } from "react";

type PreferencesContextState = {
  modalPreferencesVisible: boolean;
  openModalPreferences: () => void;
  closeModalPreferences: () => void;
};

export const PreferencesContext = createContext<PreferencesContextState>(
  {} as PreferencesContextState
);

export const PreferencesProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [modalPreferencesVisible, setModalPreferencesVisible] = useState(false);

  const openModalPreferences = () => {
    setModalPreferencesVisible(true);
  };
  const closeModalPreferences = () => {
    setModalPreferencesVisible(false);
  };

  const value = {
    modalPreferencesVisible,
    openModalPreferences,
    closeModalPreferences,
  };

  return (
    <PreferencesContext.Provider value={value}>
      {children}
    </PreferencesContext.Provider>
  );
};
