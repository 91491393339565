import React, { useEffect } from "react";
import type { AppProps } from "next/app";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import { useIsClient } from "@helpers/hooks/useIsClient";
import { AuthProvider } from "@helpers/context/AuthContext";
import { useBrowserSupport } from "@helpers/hooks/useBrowserSupport";
import { bugsnag } from "@helpers/bugsnag";

// bugsnag
const ErrorBoundary = bugsnag
  .getPlugin("react")
  ?.createErrorBoundary(React) as BugsnagErrorBoundary;

import { BugsnagErrorBoundary } from "@bugsnag/plugin-react";
import { useRouter } from "next/router";
import {
  DEFAULT_LOCALE,
  GTM_ID,
  IS_PRODUCTION,
  ROUTE_SIGNBOARD_PRINT,
  ROUTE_SUPPLIER_APPLY,
} from "@constants";
import { NextPage } from "next";
import type { ReactElement, ReactNode } from "react";
import { useLocales } from "@helpers/hooks/useLocales";
import dynamic from "next/dynamic";

import { SearchProvider } from "@helpers/context/SearchContext";
import { PreferencesProvider } from "@helpers/context/PreferencesContext";
import { LocalesProvider } from "@helpers/context/LocalesContext";

const DynamicDefaultLayout = dynamic(
  () => import("@components/layouts/components/DefaultLayout")
);
const DynamicAuthLayout = dynamic(
  () => import("@components/layouts/AuthLayout")
);
const DynamicAuthSupplierLayout = dynamic(
  () => import("@components/layouts/AuthSupplierLayout")
);
const DynamicSupplierApplyAlert = dynamic(
  () => import("@components/common/SupplierApplyAlert/SupplierApplyAlert")
);
const DynamicPayoutMissingNotification = dynamic(
  () => import("@components/common/PayoutMissingNotification")
);
const DynamicDefaultHead = dynamic(
  () => import("@components/common/DefaultHead")
);

// styles
import "../styles/global.css";
import "prismjs/themes/prism-tomorrow.css";
import "../styles/no-tailwind.css";
import { GoogleTagManager } from "@next/third-parties/google";

const REACT_QUERY_DEFAULTS = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchInterval: undefined,
};

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const { locale } = useLocales();

  useBrowserSupport();
  const isClient = useIsClient();

  const getLayout =
    Component.getLayout ??
    ((page) => {
      if (router.pathname.startsWith("/auth/supplier")) {
        // the apply flow pages cant use authsupplier beacause the supplier column gonna be changed just after the apply flow
        if (router.pathname.startsWith("/auth/supplier/apply")) {
          return <DynamicAuthLayout>{page}</DynamicAuthLayout>;
        }
        return <DynamicAuthSupplierLayout>{page}</DynamicAuthSupplierLayout>;
      }

      return <DynamicDefaultLayout>{page}</DynamicDefaultLayout>;
    });

  const queryClient = new QueryClient({
    defaultOptions: { queries: { ...REACT_QUERY_DEFAULTS } },
  });

  const changeLang = (lang: string) => {
    document.documentElement.setAttribute("lang", lang);
  };

  useEffect(() => {
    changeLang(locale || DEFAULT_LOCALE);
  }, [locale]);

  useEffect(() => {
    // disable prefetch
    if (process.env.NEXT_PUBLIC_DISABLE_PREFETCH === "true") {
      const prefetch = router.prefetch;
      router.prefetch = async () => {
        // prefetch disable
      };
      return () => {
        router.prefetch = prefetch;
      };
    } else {
      return () => {
        // empty return to prevent eslint error
      };
    }
  }, [router]);

  // all auth pages will lost souce code generation
  if (!router.isReady && router.asPath.startsWith("/auth")) {
    return <div />;
  }

  // For supplier landing page, don't show loading page from AuthProvider
  if (
    router.asPath === "/supplier" ||
    router.asPath.startsWith(ROUTE_SIGNBOARD_PRINT)
  ) {
    return (
      <LocalesProvider>
        <PreferencesProvider>
          <ErrorBoundary>
            <SearchProvider>
              <AuthProvider>
                <QueryClientProvider client={queryClient}>
                  {router.asPath != "/supplier" && (
                    <DynamicSupplierApplyAlert />
                  )}

                  <Component {...pageProps} />
                  <ToastContainer hideProgressBar />
                  {isClient && <ReactQueryDevtools initialIsOpen={false} />}
                  {IS_PRODUCTION && <GoogleTagManager gtmId={GTM_ID} />}
                </QueryClientProvider>
              </AuthProvider>
            </SearchProvider>
          </ErrorBoundary>
        </PreferencesProvider>
      </LocalesProvider>
    );
  }

  if (router.asPath.startsWith(ROUTE_SUPPLIER_APPLY)) {
    return (
      <LocalesProvider>
        <PreferencesProvider>
          <ErrorBoundary>
            <SearchProvider>
              <AuthProvider>
                <QueryClientProvider client={queryClient}>
                  <Component {...pageProps} />
                  <ToastContainer hideProgressBar />
                  {isClient && <ReactQueryDevtools initialIsOpen={false} />}
                  {IS_PRODUCTION && <GoogleTagManager gtmId={GTM_ID} />}
                </QueryClientProvider>
              </AuthProvider>
            </SearchProvider>
          </ErrorBoundary>
        </PreferencesProvider>
      </LocalesProvider>
    );
  }

  return (
    <LocalesProvider>
      <PreferencesProvider>
        <ErrorBoundary>
          <SearchProvider>
            <AuthProvider>
              <QueryClientProvider client={queryClient}>
                <DynamicDefaultHead />
                <DynamicPayoutMissingNotification />
                {getLayout(<Component {...pageProps} />)}

                <ToastContainer hideProgressBar />
                {isClient && <ReactQueryDevtools initialIsOpen={false} />}
                {IS_PRODUCTION && <GoogleTagManager gtmId={GTM_ID} />}
              </QueryClientProvider>
            </AuthProvider>
          </SearchProvider>
        </ErrorBoundary>
      </PreferencesProvider>
    </LocalesProvider>
  );
}

export default MyApp;
