import Link from "next/link";
import React, { FC, ReactNode } from "react";
import { t } from "@helpers/translate";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl";

const variants = {
  default: {
    wrapper: "",
    ul: " pr-2 py-1",
    li: "text-black",
  },
  hero: {
    wrapper: "absolute top-0 left-0 mt-3 mx-4",
    ul: "bg-black rounded-full bg-opacity-20 hover:bg-opacity-40 px-4 py-1",
    li: "text-white",
  },
};

type Variants = keyof typeof variants;

interface ListProps {
  variant: Variants;
  noWrap: boolean;
  children?: ReactNode;
}

const List: FC<ListProps> = ({ children, variant, noWrap }) => {
  const styles = variants[variant];

  return (
    <ul
      className={`flex flex-row ${
        noWrap ? "flex-nowrap" : "flex-wrap"
      } text-sm ${styles.ul}`}
    >
      {children}
    </ul>
  );
};

interface ListItemProps {
  variant: Variants;
  href?: string;
  children?: ReactNode;
}

const ListItem: FC<ListItemProps> = ({ children, href, variant }) => {
  const styles = variants[variant];
  return (
    <li className={`flex flex-row items-center whitespace-nowrap ${styles.li}`}>
      {href ? (
        <div className="flex flex-row whitespace-nowrap m-1">
          <Link
            href={getAbsoluteUrl(href)}
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
            className="hover:underline whitespace-nowrap"
          >
            {children}
          </Link>
          <div className="mx-2 opacity-40">›</div>
        </div>
      ) : (
        <div className="opacity-70">{children}</div>
      )}
    </li>
  );
};

export interface BreadcrumbItem {
  text: string;
  href: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
  className?: string;
  variant?: Variants;
  noWrap?: boolean;
}

export const Breadcrumb: FC<BreadcrumbProps> = ({
  items,
  className = "",
  variant = "default",
  noWrap = false,
}) => {
  const styles = variants[variant];

  return (
    <div
      className={`${styles.wrapper} ${className} flex ${
        noWrap ? "scrollbar-thin overflow-x-auto overflow-y-hidden" : ""
      }`}
    >
      <List variant={variant} noWrap={noWrap}>
        {items.map((item, index) => (
          <ListItem
            variant={variant}
            key={index}
            href={index === items.length - 1 ? undefined : item.href}
          >
            {item.text}
          </ListItem>
        ))}
      </List>
    </div>
  );
};
