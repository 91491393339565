import { LocalesContext } from "@helpers/context/LocalesContext";
import { useContext } from "react";

export const useLocales = () => {
  const context = useContext(LocalesContext);

  if (!context) {
    throw new Error("useLocales must be used within an LocalesProvider");
  }

  return context;
};
