import React, { FC, ReactNode, useEffect, useRef } from "react";

interface IModalProps {
  visible: boolean;
  onClose?: () => void;
  zoomInEffect?: boolean;
  children?: ReactNode;
}

const ModalAnimation: FC<IModalProps> = ({ visible, onClose, children }) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleOpenModal = () => {
    overlayRef?.current?.classList.remove("hidden");
    setTimeout(() => {
      modalRef?.current?.classList.remove("opacity-0");
      modalRef.current?.classList.remove("translate-y-full");
    }, 50);
  };

  const handleCloseModal = () => {
    modalRef.current?.classList.add("translate-y-full");
    setTimeout(() => {
      modalRef?.current?.classList.add("opacity-0");
    }, 200);
    setTimeout(() => {
      overlayRef?.current?.classList.add("hidden");
    }, 300);
    onClose && onClose();
  };

  useEffect(() => {
    if (visible) {
      handleOpenModal();
    } else {
      handleCloseModal();
    }
  }, [visible]);

  return (
    <div
      id="modal_overlay"
      ref={overlayRef}
      className="fixed inset-0 z-50 hidden w-full h-full bg-white"
    >
      <div
        id="modal"
        ref={modalRef}
        className="flex flex-col fixed bg-white w-full h-full opacity-0 transform translate-y-full transition-transform duration-500"
      >
        {children}
      </div>
    </div>
  );
};

export default ModalAnimation;
