import { PreferencesContext } from "@helpers/context/PreferencesContext";
import { useContext } from "react";

export const usePreferences = () => {
  const context = useContext(PreferencesContext);

  if (!context) {
    throw new Error(
      "usePreferences must be used within an PreferencesProvider"
    );
  }

  return context;
};
