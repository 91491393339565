import React, { FC } from "react";
import { t, Trans } from "@helpers/translate";
import { IconProps } from "../types";

export const Important: FC<IconProps> = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 22 22"
      stroke="currentColor"
    >
      <title id="GWGLogoTitle">
        {t({
          id: "common.svg.title",
          message: `GoWithGuide`,
        })}
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path
        d="M18.0859 7.5H12.3398L10.5938 2.28906C10.5078 2.03516 10.2695 1.875 10 1.875C9.73047 1.875 9.49219 2.03516 9.40625 2.28906L7.66016 7.5H1.875C1.53125 7.5 1.25 7.78125 1.25 8.125C1.25 8.16016 1.25391 8.19922 1.26172 8.23047C1.26953 8.36719 1.33203 8.51953 1.52344 8.67188L6.24609 12L4.43359 17.2695C4.34375 17.5234 4.43359 17.8086 4.64844 17.9727C4.76172 18.0547 4.86719 18.125 5 18.125C5.12891 18.125 5.28125 18.0586 5.39062 17.9844L10 14.6992L14.6094 17.9844C14.7188 18.0625 14.8711 18.125 15 18.125C15.1328 18.125 15.2383 18.0586 15.3477 17.9727C15.5664 17.8086 15.6523 17.5273 15.5625 17.2695L13.75 12L18.4336 8.64062L18.5469 8.54297C18.6484 8.43359 18.75 8.28516 18.75 8.125C18.75 7.78125 18.4297 7.5 18.0859 7.5Z"
        fill="#111827"
      />
    </svg>
  );
};
