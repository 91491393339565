import classNames from "classnames";
import React, { FC, ReactNode } from "react";
import Icon, { IconSize } from "../Icon";

type AlertStatus = "success" | "warning" | "info" | "error";

const variants = {
  success: {
    wrapper: "bg-green-100 text-green-800",
  },
  warning: {
    wrapper: "bg-yellow-100 text-gray-800",
  },
  info: {
    wrapper: "bg-blue-100 text-gray-800",
  },
  error: {
    wrapper: "bg-red-100 text-red-800",
  },
};

interface AlertProps {
  variant?: AlertStatus;
  className?: string;
  centered?: boolean;
  showIcon?: boolean;
  size?: IconSize;
  children?: ReactNode;
}

export const Alert: FC<AlertProps> = ({
  children,
  variant = "warning",
  className = "",
  centered = false,
  showIcon = true,
  size = "lg",
}) => {
  const styles = variants[variant];
  const wrapperClass = classNames(
    "flex items-center rounded",
    styles.wrapper,
    className,
    {
      "justify-center": centered,
      "py-8 px-6": !className.includes("p-"),
    }
  );
  return (
    <div className={wrapperClass}>
      {showIcon && (
        <div className="pr-6">
          <Icon icon="warning" size={size} />
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};
