import React, { FC } from "react";
import { Trans, t } from "@helpers/translate";
import { IconProps } from "../types";

export const Facebook: FC<IconProps> = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="60.734"
      height="60.733"
      x="0"
      y="0"
      enableBackground="new 0 0 60.734 60.733"
      version="1.1"
      viewBox="0 0 60.734 60.733"
      xmlSpace="preserve"
    >
      <title id="GWGLogoTitle">
        {t({
          id: "common.svg.title",
          message: `GoWithGuide`,
        })}
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path
        fill={props.fill || "currentColor"}
        d="M57.378.001H3.352A3.352 3.352 0 000 3.353v54.026a3.353 3.353 0 003.352 3.354h29.086V37.214h-7.914v-9.167h7.914v-6.76c0-7.843 4.789-12.116 11.787-12.116 3.355 0 6.232.251 7.071.36v8.198l-4.854.002c-3.805 0-4.539 1.809-4.539 4.462v5.851h9.078l-1.187 9.166h-7.892v23.52h15.475a3.355 3.355 0 003.355-3.351V3.351a3.352 3.352 0 00-3.354-3.35z"
      ></path>
    </svg>
  );
};
