import React, { useEffect, useMemo, useState } from "react";
import { Trans, t } from "@helpers/translate";
import Link from "next/link";
import { useAuth } from "@helpers/hooks/useAuth";
import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { graphql } from "@helpers/graphql";
import { Supplier } from "@graphql/types";
import { Badge } from "@components/ui/Badge";
import { Navbar } from "@components/ui/Navbar";
import { Avatar } from "@components/ui/Avatar";
import InputDestination from "../components/InputDestination";
import InputSearchHelp from "../components/InputSearchHelp";
import SupplierDropdown from "../components/SupplierDropdown";
import Preferences from "../components/Preferences";

const NavbarSupplier = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const {
    auth,
    logout,
    refreshNotificationBadge,
    setRefreshNotificationBadge,
  } = useAuth();

  const router = useRouter();

  const isSupportPage = useMemo(() => {
    return router.pathname.startsWith("/support");
  }, [router]);

  const isSupportPageSubPage = useMemo(() => {
    return router.pathname.startsWith("/support/");
  }, [router]);

  if (!auth) {
    return null;
  }

  useEffect(() => {
    if (!document) {
      return;
    }
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  const { data, refetch } = useQuery(["UserNotification"], async () => {
    const res = await graphql.UserNotification();
    return (res.me as Supplier).supplierNotification;
  });

  // This is to refresh the notification badge shown.
  // When message is marked as read, the setRefreshNotification badge is set in messages module.
  useEffect(() => {
    if (refreshNotificationBadge) {
      refetch();
      setRefreshNotificationBadge(false);
    }
  }, [refreshNotificationBadge]);

  const notificationAvailable = () => {
    if (data?.paidBookingsCount || 0 > 0) {
      return true;
    }
    if (data?.reviewNotRepliedCount || 0 > 0) {
      return true;
    }
    if (data?.unReadMessagesCount || 0 > 0) {
      return true;
    }
    return false;
  };

  return (
    <Navbar fixed={true}>
      {/* navbar support page */}
      {isSupportPage ? (
        <div className="flex flex-row items-center w-full">
          {!isSupportPageSubPage && <div className={"flex w-12 md:hidden"} />}
          <div className="flex mx-auto md:mx-0">
            <Navbar.Brand>
              {isSupportPageSubPage && (
                <div className="mx-2 md:mx-4">
                  <InputSearchHelp />
                </div>
              )}
            </Navbar.Brand>
          </div>
        </div>
      ) : (
        // navbar commom
        <div className="flex flex-row w-full">
          <Navbar.Search onClick={() => setIsSearchOpen(!isSearchOpen)} />
          <div className="flex mx-auto md:mx-0">
            <Navbar.Brand>
              <div className="md:mx-4">
                <InputDestination
                  isOpen={isSearchOpen}
                  setIsOpen={setIsSearchOpen}
                />
              </div>
            </Navbar.Brand>
          </div>
        </div>
      )}

      <Navbar.Menu
        onClick={() => setIsOpen(!isOpen)}
        hasNotification={notificationAvailable()}
      />

      <Navbar.Right>
        <Navbar.Nav>
          <Navbar.NavItem className="hidden lg:block px-0">
            <Link
              href="/auth/messages"
              title={t({
                id: "common.link.title",
                message: "GoWithGuide - Private Tours & Local Tour Guides",
              })}
            >
              <Badge count={data?.unReadMessagesCount}>
                <Trans id="navbar.messages">Messages</Trans>
              </Badge>
            </Link>
          </Navbar.NavItem>

          <Navbar.NavItem className="hidden lg:block">
            <Link
              href="/auth/supplier/calendar"
              title={t({
                id: "common.link.title",
                message: "GoWithGuide - Private Tours & Local Tour Guides",
              })}
            >
              <Trans id="navbar.calendar">Calendar</Trans>
            </Link>
          </Navbar.NavItem>

          <Navbar.NavItem className="hidden lg:block">
            <Link
              href="/support"
              title={t({
                id: "common.link.title",
                message: "GoWithGuide - Private Tours & Local Tour Guides",
              })}
            >
              <Trans id="navbar.help">Help</Trans>
            </Link>
          </Navbar.NavItem>

          <Navbar.NavItem>
            <SupplierDropdown notification={data} />
          </Navbar.NavItem>
        </Navbar.Nav>
      </Navbar.Right>
      {isOpen && (
        <Navbar.MenuLayer isOpen={isOpen} onClick={() => setIsOpen(false)}>
          <div className="flex items-center mb-4">
            <div className="flex items-center font-semibold text-lg whitespace-nowrap">
              <Avatar src={auth.picture?.url} size="xs" className="mr-2" />
              {auth.displayName}
            </div>
          </div>
          <Navbar.Divider />
          <Navbar.MenuItem href="/auth/messages">
            <Badge count={data?.unReadMessagesCount}>
              <Trans id="navbar.messages">Messages</Trans>
            </Badge>
          </Navbar.MenuItem>
          <Navbar.MenuItem href="/auth/supplier/analytics">
            <Trans id="navbar.analytics">Analytics</Trans>
          </Navbar.MenuItem>
          <Navbar.MenuItem href="/auth/supplier/bookings">
            <Badge count={data?.paidBookingsCount}>
              <Trans id="navbar.bookings">Bookings</Trans>
            </Badge>
          </Navbar.MenuItem>
          <Navbar.MenuItem href="/auth/supplier/calendar">
            <Trans id="navbar.calendar">Calendar</Trans>
          </Navbar.MenuItem>
          <Navbar.Divider />
          <Navbar.MenuItem href="/auth/account">
            <Trans id="navbar.account">Account</Trans>
          </Navbar.MenuItem>
          <Navbar.MenuItem href="/auth/posts">
            <Trans id="navbar.blog">Blog</Trans>
          </Navbar.MenuItem>
          <Navbar.MenuItem href="/auth/payments/month">
            <Trans id="navbar.payments">Payments</Trans>
          </Navbar.MenuItem>
          <Navbar.MenuItem href="/auth/supplier/reviews">
            <Badge count={data?.reviewNotRepliedCount}>
              <Trans id="navbar.reviews">Reviews</Trans>
            </Badge>
          </Navbar.MenuItem>
          <Navbar.MenuItem href="/auth/supplier/tours">
            <Trans id="navbar.tours">Tours</Trans>
          </Navbar.MenuItem>
          <Navbar.Divider />
          <Navbar.MenuItem href="/support">
            <Trans id="navbar.help">Help</Trans>
          </Navbar.MenuItem>
          <Navbar.Divider />
          <Navbar.MenuItem>
            <Preferences />
          </Navbar.MenuItem>
          <div className="text-lg font-medium my-4 block hover:underline cursor-pointer text-red-500">
            <a
              title={t({
                id: "common.link.title",
                message: "GoWithGuide - Private Tours & Local Tour Guides",
              })}
              href="#"
              onClick={() => logout("", true)}
            >
              <Trans id="navbar.logout">Logout</Trans>
            </a>
          </div>
        </Navbar.MenuLayer>
      )}
    </Navbar>
  );
};

export default NavbarSupplier;
