import React, { HTMLAttributes, ReactNode } from "react";
import { FC } from "react";
import { PostCardFragment } from "@graphql/types";
import classNames from "classnames";
import { Title } from "@components/ui/Title";
import PostCard from "../PostCard";

type Props =
  | {
      orientation: "vertical";
      columns: "4" | "3" | "2";
      title?: string;
      data?: PostCardFragment[];
      showUser?: boolean;
      isLoading?: boolean;
      className: HTMLAttributes<HTMLDivElement>["className"];
      children?: ReactNode;
    }
  | {
      orientation: "horizontal";
      rows: "4" | "3" | "2";
      title?: string;
      data?: PostCardFragment[];
      showUser?: boolean;
      isLoading?: boolean;
      className: HTMLAttributes<HTMLDivElement>["className"];
      children?: ReactNode;
    };

const PostsBlock: FC<Props> = (payload) => {
  if (payload.data?.length === 0) {
    return null;
  }

  if (payload.orientation === "vertical") {
    const className = classNames(
      "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4",
      {
        "xl:grid-cols-4": payload.columns === "4",
        "lg:grid-cols-3": payload.columns === "3",
        "lg:grid-cols-2": payload.columns === "2",
      }
    );

    return (
      <div className="mb-8">
        {payload.title && (
          <Title as="h2" size="md" divider>
            {payload.title}
          </Title>
        )}
        <div className={className}>
          {payload.data &&
            payload.data.map((node) => (
              <PostCard
                key={node.id}
                post={node}
                showUser={payload.showUser}
                orientation="vertical"
              />
            ))}
        </div>
        {payload.children && (
          <div className="text-center mt-8">{payload.children}</div>
        )}
      </div>
    );
  }

  if (payload.orientation === "horizontal") {
    return (
      <div className="flex flex-col w-full">
        {payload.title && (
          <Title as="h2" size="md" divider>
            {payload.title}
          </Title>
        )}
        <div className="flex flex-col w-full">
          {payload.data &&
            payload.data.map((node) => (
              <PostCard
                key={node.id}
                post={node}
                showUser={payload.showUser}
                orientation="horizontal"
              />
            ))}
        </div>
        {payload.children && (
          <div className="text-center mt-8">{payload.children}</div>
        )}
      </div>
    );
  }

  return null;
};

export default PostsBlock;
