import Icon from "@components/ui/Icon";
import { useRouter } from "next/router";
import React, { KeyboardEvent, useState } from "react";

const SupportSearch = () => {
  const router = useRouter();
  const [searchText, setSearchText] = useState("");

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && searchText) {
      router.push(`/support/search?q=${encodeURI(searchText)}`);
    }
  };

  return (
    <div className="flex justify-center text-black w-full h-14">
      <div className="flex w-full border-2 rounded-full items-center">
        <svg
          className="w-6 h-6 ml-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.5}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>

        <div className="flex w-full relative">
          <input
            type="text"
            placeholder="How can we help you?"
            onKeyDown={handleKeyPress}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.currentTarget.value);
            }}
            className="flex w-full text-black h-full border-0 outline-none rounded-full px-4 pr-12 overflow-ellipsis"
          />
          {searchText.length > 0 && (
            <button
              onClick={() => setSearchText("")}
              className="absolute top-1/2 transform -translate-y-1/2 right-4"
            >
              <div className="p-2 hover:bg-gray-200 rounded-lg transition-all duration-300">
                <Icon icon="x" size="xs" />
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SupportSearch;
