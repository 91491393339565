import React from "react";
import enMessages from "../locales/en/messages.json";
import jaMessages from "../locales/ja/messages.json";
import { COOKIE_LOCALE } from "@constants";
import cookies from "./cookies";

type MessageId = string;
type TranslatedString = string;
type Messages = Record<MessageId, TranslatedString>;
type PlaceholderValues = Record<string, string | number | undefined>;

interface TranslationOptions {
  id: MessageId;
  message?: TranslatedString;
  values?: PlaceholderValues;
}

interface PluralOptions {
  zero?: TranslatedString;
  one: TranslatedString;
  other: TranslatedString;
}

interface SelectOptions extends Record<string, TranslatedString> {
  other: TranslatedString;
}

interface TransProps {
  id?: MessageId;
  values?: PlaceholderValues;
  components?: Record<string, React.ReactElement>;
  children?: React.ReactNode;
}

// Load messages from JSON file

// Load messages from JSON file
const messageRecords = (locale: string): Messages => {
  if (locale === "ja") {
    return jaMessages;
  }
  return enMessages;
};

const replacePlaceholders = (
  id: string,
  str: TranslatedString,
  values?: PlaceholderValues,
  message?: string
): TranslatedString => {
  if (!values || typeof values !== "object") {
    if (str.includes("{")) {
      return message || str;
    }
    return str;
  }

  return Object.entries(values).reduce((acc, [key, value]) => {
    if (value === undefined) return acc;
    const regex = new RegExp(`\\{${key}\\}`, "g");
    return acc.replace(regex, value.toString());
  }, str);
};

// Core translation function
// Always applies placeholder replacement regardless of the translation source
const translate = ({
  id,
  message,
  values,
}: TranslationOptions): TranslatedString => {
  const locale = !cookies().get("NEXT_FIRST_RENDER_DONE")
    ? "en"
    : ((cookies().get(COOKIE_LOCALE) || "en") as "en" | "ja");
  const translation = messageRecords(locale)[id] || message || id;

  // the wrapping the t with plural function was creating the issue and this was used at 9 places, hence went for directly the below approach, and also in json of ja it was blank
  if (translation.includes(", plural,")) {
    return message || "";
  }

  return replacePlaceholders(id, translation, values, message);
};

export const t = (options: TranslationOptions): TranslatedString =>
  translate(options);

export const plural = (
  count: number,
  options: PluralOptions
): TranslatedString => {
  if (count === 0 && options.zero) return options.zero;
  return count === 1 ? options.one : options.other;
};

export const select = (
  value: string,
  options: SelectOptions
): TranslatedString => {
  return options[value] || options.other;
};

// Trans component
export const Trans: React.FC<TransProps> = ({
  id,
  values,
  components,
  children,
}) => {
  const translation = React.useMemo(() => {
    return translate({ id: id ?? "", message: children?.toString(), values });
  }, [id, values, children]);

  if (typeof translation !== "string") {
    console.error(
      "Translation function returned a non-string value:",
      translation
    );
    return React.createElement(React.Fragment, null, children);
  }

  const renderNestedComponents = (children: React.ReactNode) => {
    return React.Children.map(children, (child) => {
      if (typeof child === "string") {
        return child;
      }

      if (React.isValidElement(child)) {
        return React.cloneElement(child);
      }

      return child;
    });
  };

  const renderedChildren = renderNestedComponents(children);

  if (components) {
    const parts = translation.split(/(<\d+>.*?<\/\d+>)/);
    const processedParts = parts.map((part, index) => {
      const match = part.match(/^<(\d+)>(.*)<\/\1>$/);
      if (match) {
        const [, num, content] = match;
        const Component = components[num];
        return Component
          ? React.cloneElement(Component, { key: index }, content)
          : content;
      }
      return part;
    });

    const rawHTML = processedParts.join("");
    return React.createElement(
      React.Fragment,
      {},
      <div dangerouslySetInnerHTML={{ __html: rawHTML }} />
    );
  }

  return React.createElement(React.Fragment, null, renderedChildren);
};
