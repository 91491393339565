import React, { FC, ReactNode } from "react";
import {
  Container,
  Body,
  Description,
  Footer,
  Image,
  Label,
  Price,
  Title,
  Wrapper,
  Tags,
  Tag,
} from "./styles";

interface CardComponents {
  Container: typeof Container;
  Body: typeof Body;
  Description: typeof Description;
  Footer: typeof Footer;
  Image: typeof Image;
  Label: typeof Label;
  Price: typeof Price;
  Title: typeof Title;
  Tags: typeof Tags;
  Tag: typeof Tag;
}

export const Card: FC<{ className?: string; children: ReactNode }> &
  CardComponents = ({ children, className = "" }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

Card.Container = Container;
Card.Body = Body;
Card.Description = Description;
Card.Footer = Footer;
Card.Image = Image;
Card.Label = Label;
Card.Price = Price;
Card.Title = Title;
Card.Tags = Tags;
Card.Tag = Tag;
