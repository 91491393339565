import React, { FC } from "react";
import Link from "next/link";
import { t } from "@helpers/translate";
import { PostCardFragment } from "@graphql/types";
import { getDateFormat } from "@helpers/getDateFormat";
import { Card } from "@components/ui/Card";
import { Avatar } from "@components/ui/Avatar";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl";
import { Image } from "@components/ui/Image";

type Props = {
  orientation: "vertical" | "horizontal";
  showUser?: boolean;
  post: PostCardFragment;
};
const PostCard: FC<Props> = ({ orientation, showUser = true, post }) => {
  if (orientation === "vertical") {
    return (
      <Link
        href={getAbsoluteUrl(`${post.url}`)}
        title={t({
          id: "common.link.title",
          message: "GoWithGuide - Private Tours & Local Tour Guides",
        })}
      >
        <Card>
          <Card.Image src={post?.picture?.url} alt={post.title}>
            {post.category && (
              <div className="absolute text-xs px-2 py-1 bottom-2 right-2 rounded-xl bg-gray-200  text-gray-700">
                <div className="truncate">{post.category?.name}</div>
              </div>
            )}
          </Card.Image>
          <Card.Container>
            <Card.Body className="h-48">
              <Card.Title className="h-14 truncate-2">{post.title}</Card.Title>

              {/* Country, Area */}
              {post.country && (
                <div className="flex max-w-md my-3 truncate">
                  <p className="truncate bg-gray-200 bg-opacity-70 text-gray-600 rounded px-3 py-1 text-xs">
                    {`${post.city?.name != null ? `${post.city?.name}, ` : ""}`}

                    {`${
                      post.country?.name != null ? `${post.country?.name}` : ""
                    }`}
                  </p>
                </div>
              )}

              {!post.country && (
                <div className="flex max-w-md my-3 truncate">
                  <p className="truncate bg-gray-200 bg-opacity-70 text-gray-600 rounded px-3 py-1 text-xs">
                    Global
                  </p>
                </div>
              )}

              <Card.Description className="truncate-4 h-16">
                {post.overview}
              </Card.Description>
            </Card.Body>

            {showUser && (
              <Card.Footer>
                <Avatar
                  src={post.user.picture && post.user.picture.url}
                  alt={post.user.displayName}
                  size="xs"
                />
                <div className="ml-2 w-full grid grid-cols-2 lg:grid lg:grid-cols-2 max-md:grid max-md:grid-cols-2">
                  <div className="float-left text-blue-900 text-sm font-medium truncate ">
                    {post.user.displayName}
                  </div>

                  {post.contentUpdatedAt && (
                    <div className="float-right text-gray-500 text-xs truncate text-right">
                      {getDateFormat(post.contentUpdatedAt, "MMM dd, yyyy")}
                    </div>
                  )}
                </div>
              </Card.Footer>
            )}
          </Card.Container>
        </Card>
      </Link>
    );
  }

  if (orientation === "horizontal") {
    return (
      <Link
        href={getAbsoluteUrl(`${post.url}`)}
        title={t({
          id: "common.link.title",
          message: "GoWithGuide - Private Tours & Local Tour Guides",
        })}
      >
        <Card className="flex flex-row w-full">
          <div className="flex flex-col w-1/3">
            <div className="relative h-full w-full">
              <Image
                src={(post.picture && post.picture.url) || ""}
                alt={post.title || ""}
                className="w-full h-full object-cover"
                fill={true}
                activeCache={true}
              />
              {post.category && (
                <div className="absolute text-xs px-2 py-1 bottom-2 right-2 rounded-xl bg-gray-200  text-gray-700">
                  <div className="truncate">{post.category?.name}</div>
                </div>
              )}
            </div>
          </div>
          <div className="flex w-2/3">
            <Card.Container className="flex flex-col w-full">
              <Card.Body>
                <Card.Title className="truncate w-full">
                  {post.title}
                </Card.Title>

                {/* Country, Area */}
                {post.country && (
                  <div className="flex max-w-md my-3 truncate">
                    <p className="truncate bg-gray-200 bg-opacity-70 text-gray-600 rounded px-3 py-1 text-xs">
                      {`${
                        post.city?.name != null ? `${post.city?.name}, ` : ""
                      }`}

                      {`${
                        post.country?.name != null
                          ? `${post.country?.name}`
                          : ""
                      }`}
                    </p>
                  </div>
                )}

                <Card.Description className="truncate-3">
                  {post.overview}
                </Card.Description>
              </Card.Body>
              {showUser && (
                <div className="flex w-full flex-row items-center">
                  <Avatar
                    src={post.user.picture && post.user.picture.url}
                    alt={post.user.displayName}
                    size="xs"
                  />
                  <div className="ml-2 w-full grid grid-cols-2 lg:grid lg:grid-cols-2 max-md:grid max-md:grid-cols-2">
                    <div className="float-left text-blue-900 text-sm font-medium truncate ">
                      {post.user.displayName}
                    </div>

                    {post.contentUpdatedAt && (
                      <div className="ml-auto text-gray-500 text-xs truncate text-right">
                        {getDateFormat(post.contentUpdatedAt, "MMM dd, yyyy")}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Card.Container>
          </div>
        </Card>
      </Link>
    );
  }

  return null;
};

export default PostCard;
