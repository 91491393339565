import React, { FC } from "react";
import { Facebook } from "./icons/Facebook";
import { Google } from "./icons/Google";
import { Close } from "./icons/Close";
import { ArrowUp } from "./icons/ArrowUp";
import { ArrowDown } from "./icons/ArrowDown";
import { Price } from "./icons/Price";
import { Play } from "./icons/Play";
import { Setting } from "./icons/Setting";
import { Camera } from "./icons/Camera";
import { Smile } from "./icons/Smile";
import { Flag } from "./icons/Flag";
import { Like } from "./icons/Like";
import { Warning } from "./icons/Warning";
import { Success } from "./icons/Success";
import { Info } from "./icons/Info";
import { Error } from "./icons/Error";
import { User } from "./icons/User";
import { Globe } from "./icons/Globe";
import { Ticket } from "./icons/Ticket";
import { Car } from "./icons/Car";
import { Bicycle } from "./icons/Bicycle";
import { Check } from "./icons/Check";
import { Plus } from "./icons/Plus";
import { Minus } from "./icons/Minus";
import { ArrowNext } from "./icons/ArrowNext";
import { ArrowBack } from "./icons/ArrowBack";
import { ArrowForward } from "./icons/ArrowForward";
import { Chat } from "./icons/Chat";
import { Marker } from "./icons/Marker";
import { Time } from "./icons/Time";
import { Calendar } from "./icons/Calendar";
import { Lock } from "./icons/Lock";
import { CheckCircle } from "./icons/CheckCircle";
import { CloseCircle } from "./icons/CloseCircle";
import { List } from "./icons/List";
import { StarOutline } from "./icons/StarOutline";
import { Star } from "./icons/Star";
import { Print } from "./icons/Print";
import { Archive } from "./icons/Archive";
import { Important } from "./icons/Important";
import { TemplateIcon } from "./icons/TemplateIcon";
import { Announce } from "./icons/Announce";
import { CheckBoldIcon } from "./icons/CheckBoldIcon";
import { Twitter } from "./icons/Twitter";
import { Instagram } from "./icons/Instagram";
import { FacebookRound } from "./icons/FacebookRound";
import { Share } from "./icons/Share";
import { Copy } from "./icons/Copy";
import { Email } from "./icons/Email";
import { Messenger } from "./icons/Messenger";
import { Whatsapp } from "./icons/Whatsapp";
import { XTwitter } from "./icons/XTwitter";
import { Brush } from "./icons/Brush";
import { FaqDocument } from "./icons/FaqDocument";
import { LostCharacter } from "./icons/LostCharacter";
import { twMerge } from "tailwind-merge";
import { ArrowPrev } from "./icons/ArrowPrev";
import { Guard } from "./icons/Guard";
import { CheckBlueFilled } from "./icons/CheckBlueFilled";
import { VerifiedGlyph } from "./icons/VerifiedGlyph";
import { Lens } from "./icons/Lens";
import { Arrow } from "./icons/Arrow";
import { People } from "./icons/People";
import { Talk } from "./icons/Talk";
import { Value } from "./icons/Value";
import { Envelope } from "./icons/Envelope";
import { X } from "./icons/X";

export const icons = {
  facebook: Facebook,
  google: Google,
  close: Close,
  brush: Brush,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  arrowNext: ArrowNext,
  arrowBack: ArrowBack,
  arrowForward: ArrowForward,
  arrowPrev: ArrowPrev,
  price: Price,
  play: Play,
  setting: Setting,
  camera: Camera,
  smile: Smile,
  flag: Flag,
  guard: Guard,
  like: Like,
  warning: Warning,
  success: Success,
  info: Info,
  error: Error,
  user: User,
  globe: Globe,
  ticket: Ticket,
  car: Car,
  bicycle: Bicycle,
  check: Check,
  checkCircle: CheckCircle,
  closeCircle: CloseCircle,
  plus: Plus,
  minus: Minus,
  messenger: Messenger,
  calendar: Calendar,
  chat: Chat,
  marker: Marker,
  time: Time,
  lock: Lock,
  list: List,
  starOutline: StarOutline,
  star: Star,
  share: Share,
  print: Print,
  archive: Archive,
  announce: Announce,
  important: Important,
  template: TemplateIcon,
  checkBold: CheckBoldIcon,
  twitter: Twitter,
  instagram: Instagram,
  facebookRound: FacebookRound,
  copy: Copy,
  whatsapp: Whatsapp,
  email: Email,
  xtwitter: XTwitter,
  faqDocument: FaqDocument,
  lostCharacter: LostCharacter,
  verifiedGlyph: VerifiedGlyph,
  checkBlueFilled: CheckBlueFilled,
  lens: Lens,
  arrow: Arrow,
  people: People,
  talk: Talk,
  value: Value,
  envelope: Envelope,
  x: X,
};

export type IconType = keyof typeof icons;

const sizes = {
  xs: "h-4 w-4",
  sm: "h-6 w-6",
  md: "h-8 w-8",
  lg: "h-12 w-12",
  xl: "h-16 w-16",
  none: "",
};

export type IconSize = keyof typeof sizes;

interface IconProps {
  icon: IconType;
  size?: IconSize;
  className?: string;
  fill?: string;
  title?: string;
  onClick?: () => void;
  strokeWidth?: number;
}

const Icon: FC<IconProps> = ({
  icon,
  className,
  onClick,
  size = "md",
  fill = "none",
  title = "GoWithGuide",
  strokeWidth = 1,
}) => {
  const Component = icons[icon];
  return (
    <Component
      className={twMerge(`${sizes[size]}`, className)}
      onClick={onClick}
      fill={fill}
      title={title}
      strokeWidth={strokeWidth}
    />
  );
};

export default Icon;
