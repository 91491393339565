import React, { FC, useState } from "react";
import ModalBigPicture from "@components/ui/GalleryNew/ModalBigPicture";
import { ReviewCardFragment } from "@graphql/types";
import { getDuration } from "@helpers/getDuration";
import { useLocales } from "@helpers/hooks/useLocales";
import { Card } from "@components/ui/Card";
import { Avatar } from "@components/ui/Avatar";
import { Rating } from "@components/ui/Rating";
import { Trans } from "@helpers/translate";
import formatDateToDD from "@helpers/formatDateToDD";
import Icon from "@components/ui/Icon";
import { Image } from "@components/ui/Image";
import Link from "next/link";

type Props = ReviewCardFragment;

const ReviewCard: FC<Props> = ({ ...review }) => {
  const { locale } = useLocales();

  const [modalBigPicture, setModalBigPicture] = useState({
    visible: false,
    index: 0,
  });

  const handleReviewCountClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(
      `/supplier/${review.supplier.id}/#reviews`,
      "_blank",
      "noopener,noreferrer" //No referrer, no context access
    );
  };

  return (
    <Card>
      <Card.Container>
        {/* User */}
        <div className="flex flex-col md:flex-row items-start mb-4 border-b pb-4 w-full">
          <div className="flex md:w-auto w-full">
            <div className="flex items-center">
              <div>
                <Avatar
                  src={review.user.picture?.url}
                  alt={review.user.displayName}
                  size="xs"
                  className="mr-2"
                />
              </div>
              <div>
                <p className="mr-2 whitespace-nowrap">
                  {review.user.displayName}
                </p>
                {review.rating > 0 && (
                  <Rating
                    rate={review.rating}
                    alwaysShowDecimals={false}
                    size="xs"
                    variant="stars"
                    className="mr-3"
                  />
                )}
              </div>
            </div>
            {/* mobile only */}
            <div className="flex items-center md:hidden ml-auto">
              <div className="text-sm text-gray-500">
                {formatDateToDD(review.createdAt, locale)}
              </div>
            </div>
          </div>

          {/* Tour details */}
          {review.booking && (
            <a
              href={review.booking.tour?.url || review.supplier.url}
              className="hover:bg-gray-100 px-4 mt-2 md:mt-0 rounded-xl transition-colors duration-200 w-full md:w-6/12"
            >
              <div className="flex flex-row">
                <div className="w-full">
                  <p className="text-lg font-semibold text-blue-700 mb-2 truncate">
                    {review.booking.tour?.title || review.booking.title}
                  </p>
                  <Card.Tags>
                    {review.booking.city && (
                      <Card.Tag className="whitespace-nowrap">
                        {review.booking.city.name}
                      </Card.Tag>
                    )}
                    {review.booking?.duration && (
                      <Card.Tag>
                        {getDuration(review.booking.duration)}
                      </Card.Tag>
                    )}
                    {/* Custom booking */}
                    {!review.booking.tour && (
                      <div className="flex flex-row items-center">
                        <div className="rounded-full p-0.5 bg-primary-light">
                          <Icon icon="brush" size="xs" fill="#ffffff" />
                        </div>
                        <p className="font-bold ml-1 text-xs text-gray-600">
                          <Trans id="reviews.booking-customized">
                            Customized
                          </Trans>
                        </p>
                      </div>
                    )}
                  </Card.Tags>
                </div>
              </div>
            </a>
          )}
          <div className="hidden whitespace-nowrap md:flex ml-auto">
            <div className="text-sm text-gray-500">
              {formatDateToDD(review.createdAt, locale)}
            </div>
          </div>
        </div>

        {/* Title */}
        {review.title && (
          <p className="text-lg font-semibold text-gray-800 mb-2">
            “{review.title}”
          </p>
        )}

        {/* Message */}
        <p className="text-gray-600 text-sm leading-5 mb-4">{review.message}</p>

        <div className="flex flex-row flex-wrap gap-2 my-4">
          {review.photos.map((photo, index) => (
            <button
              key={index}
              className="w-16 h-16"
              onClick={() =>
                setModalBigPicture({
                  visible: true,
                  index: index,
                })
              }
            >
              <Image
                className="w-full h-full object-cover rounded-lg"
                src={photo.urlXS}
                alt={`${review.booking?.tour?.title || "custom tour"} - ${
                  index + 1
                }`}
                activeCache={true}
                width={64}
                height={64}
              />
            </button>
          ))}
          <ModalBigPicture
            items={review.photos.map((photo, index) => ({
              src: photo.urlLG,
              alt: `${review.booking?.tour?.title || "custom tour"} - ${
                index + 1
              }`,
              type: "image",
            }))}
            visible={modalBigPicture.visible}
            currentIndex={modalBigPicture.index}
            onRequestClose={() =>
              setModalBigPicture({
                ...modalBigPicture,
                visible: false,
              })
            }
          />
        </div>

        {/* Reply */}
        {review.response && (
          <Link
            href={`/supplier/${review.supplier.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="bg-gray-100 p-4 rounded">
              <div className="flex flex-row">
                <Avatar
                  src={review.supplier.picture?.url}
                  alt={review.user.displayName}
                  size="lg"
                  className="mr-2"
                />
                <div className="flex flex-col">
                  <div className="flex flex-row mb-2 items-center">
                    <p className="text-lg font-semibold text-secondary">
                      {review.supplier.displayName}
                    </p>
                    <button
                      onClick={handleReviewCountClick}
                      className="text-xs font-bold underline text-blue-700 ml-2 py-1"
                    >
                      ({review.supplier.stats.reviewsCount} reviews)
                    </button>
                  </div>
                  <p
                    className="text-gray-600 text-sm"
                    style={{ overflowWrap: "anywhere" }}
                  >
                    {review.response}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        )}
      </Card.Container>
    </Card>
  );
};

export default ReviewCard;
