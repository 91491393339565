import React, { ReactNode } from "react";
import { FC } from "react";
import { ReviewCardFragment, ReviewsQueryVariables } from "@graphql/types";
import classNames from "classnames";
import { Title } from "@components/ui/Title";
import ReviewCard from "../ReviewCard";

interface Props extends ReviewsQueryVariables {
  title?: string;
  data?: ReviewCardFragment[];
  columns?: "1" | "2";
  children?: ReactNode;
}

const ReviewsBlock: FC<Props> = ({ title, data, children, columns = "1" }) => {
  if (data?.length === 0) {
    return null;
  }

  const className = classNames("grid grid-cols-1 gap-4", {
    "lg:grid-cols-1": columns === "1",
    "lg:grid-cols-2": columns === "2",
  });

  return (
    <div className="mb-20">
      {title && (
        <Title as="h2" size="md" divider>
          {title}
        </Title>
      )}
      <div className={className}>
        {data && data.map((node) => <ReviewCard key={node.id} {...node} />)}
      </div>
      {children && <div className="text-center mt-8">{children}</div>}
    </div>
  );
};

export default ReviewsBlock;
