import React, { FC, ReactNode } from "react";
import {
  COOKIE_CURRENCY,
  CURRENCIES,
  CURRENCY_BY_LOCALE,
  ILOCALES,
  LOCALES,
} from "@constants";
import { useCookie } from "@helpers/hooks/useCookie";
import { useRouter } from "next/router";
import cookies from "@helpers/cookies";
import { getCurrencySymbol } from "@helpers/getCurrencyFormat";
import { t, Trans } from "@helpers/translate";
import Modal from "@components/ui/Modal";
import { useLocales } from "@helpers/hooks/useLocales";
import { usePreferences } from "@helpers/hooks/usePreferences";

const Preferences = () => {
  const { openModalPreferences } = usePreferences();
  const { locale } = useLocales();

  const currentLanguage = locale.toUpperCase();
  const currentCurrency = useCookie(
    COOKIE_CURRENCY,
    CURRENCY_BY_LOCALE[
      currentLanguage.toLowerCase() as keyof typeof CURRENCY_BY_LOCALE
    ]
  );

  return (
    <div
      className="whitespace-nowrap cursor-pointer"
      onClick={openModalPreferences}
    >
      {currentCurrency} | {currentLanguage}
    </div>
  );
};

const Title: FC<{ children: ReactNode }> = ({ children }) => (
  <h3 className="text-base mb-2 text-gray-600 uppercase">{children}</h3>
);

const List: FC<{ children: ReactNode }> = ({ children }) => (
  <ul className="flex flex-wrap">{children}</ul>
);

const Item: FC<{ onClick?: () => void; children: ReactNode }> = ({
  children,
  ...props
}) => (
  <li
    className="w-1/3 md:w-1/4 pb-4 text-link hover:text-link-dark hover:underline cursor-pointer"
    {...props}
  >
    {children}
  </li>
);

export const PreferenceModal = () => {
  const router = useRouter();
  const { changeLocale } = useLocales();
  const { closeModalPreferences } = usePreferences();

  return (
    <Modal
      title={t({ id: "preferences.modal.title", message: "Preferences" })}
      onClose={closeModalPreferences}
    >
      <div className="mb-4">
        <Title>
          <Trans id="preferences.modal.languages">LANGUAGES</Trans>
        </Title>
        <List>
          {Object.keys(LOCALES).map((locale: ILOCALES) => {
            return (
              <Item
                key={locale}
                onClick={() => {
                  changeLocale(locale);
                  router.reload();
                  closeModalPreferences();
                }}
              >
                {LOCALES[locale as keyof typeof LOCALES]}
              </Item>
            );
          })}
        </List>
      </div>
      <div>
        <Title>
          <Trans id="preferences.modal.currencies">CURRENCIES</Trans>
        </Title>
        <List>
          {CURRENCIES.map((currency) => (
            <Item
              key={currency}
              onClick={() => {
                cookies().set(COOKIE_CURRENCY, currency);
                router.reload();
              }}
            >
              {currency} ({getCurrencySymbol(currency)})
            </Item>
          ))}
        </List>
      </div>
    </Modal>
  );
};

export default Preferences;
