import React from "react";
import { graphql } from "@helpers/graphql";
import { useQuery } from "react-query";
import { Alert } from "@components/ui/Alert";

const AnnouncementBanner = () => {
  const { data } = useQuery(["activeAnnouncement"], () => {
    return graphql.activeAnnouncement();
  });

  return (
    <>
      {data && data?.activeAnnouncement?.announcement && (
        <Alert
          variant="warning"
          showIcon={true}
          size="sm"
          className="flex justify-center p-3"
        >
          <div
            dangerouslySetInnerHTML={{
              __html: data?.activeAnnouncement?.announcement || "",
            }}
            className={`text-left justify-center text-sm`}
          />
        </Alert>
      )}
    </>
  );
};

export default AnnouncementBanner;
